import { Component, createResource, createSignal, For, Show } from "solid-js";
import AdminPanel from "../../../../components/AdminPanel";
import { fetchAPI } from "../../../../utils";

const fetchErrors = async () => {
  const res = await fetchAPI("/users/:discordID/guilds/:guildID/servers/:serverID/errors", "GET");
  if (!res.ok) {
    return {};
  }
  return await res.json();
};

// const fetchErrorsChannel = async () => {
//   const res = await fetchAPI("/users/:discordID/guilds/:guildID/servers/:serverID/errors/channels", "GET");
//   if (!res.ok) {
//     return {};
//   }
//   return await res.json();
// };

interface AddError {
  data: Object;
  addon: string;
  count: number;
  realm: string;
  title: string;
  id: string;
  createAt: Date;
}

const [selectError, setSelectError] = createSignal(0);
let idxLog = 0;
const AddErrorComponent: Component<AddError> = (props) => {
  if (props.data.stack) {
    props.data.stack = JSON.parse(props.data.stack);
  }
  const errorContentStr = JSON.stringify(props.data, null, 2).split("\n");
  idxLog++;
  const localIdxLog = idxLog;
  return (
    <>
      <tr>
        <td class="text-gray-300 text-nowrap">{props.createAt.toLocaleString()}</td>
        <td class="text-white text-nowrap">{props.addon}</td>
        <td class="text-white">{props.realm}</td>
        <td class="text-white">{props.count}</td>
        <td class="text-white break-words">{props.title}</td>
        <td>
          <div class="flex gap-2 justify-center">
            <div
              class="tooltip tooltip-info"
              data-tip="Show More"
              onClick={() => {
                if (selectError() === localIdxLog) {
                  setSelectError(0);
                } else {
                  setSelectError(localIdxLog);
                }
              }}
            >
              <Show when={selectError() === localIdxLog}>
                <i class="fas fa-chevron-up"></i>
              </Show>
              <Show when={selectError() !== localIdxLog}>
                <i class="fas fa-chevron-down"></i>
              </Show>
            </div>
            <Show when={props.id !== "" && props.id !== "0"}>
              <div class="tooltip tooltip-info" data-tip="Open Workshop">
                <a href={`https://steamcommunity.com/sharedfiles/filedetails/?id=${props.id}`} target="_blank">
                  <i class="fas fa-external-link-alt"></i>
                </a>
              </div>
            </Show>
            <div class="tooltip tooltip-info" data-tip="Download">
              <a
                href={`data:text/plain;charset=utf-8,${encodeURIComponent(errorContentStr.join("\n"))}`}
                download={`log-${props.createAt.toLocaleString()}.json`}
              >
                <i class="fas fa-download"></i>
              </a>
            </div>
          </div>
        </td>
      </tr>

      <Show when={selectError() === localIdxLog}>
        <tr>
          <td colspan="6">
            <pre>
              <code>{errorContentStr.join("\n")}</code>
            </pre>
          </td>
        </tr>
      </Show>
    </>
  );
};

const ServerErrors: Component = () => {
  const [errors, { mutate: errorsMutate }] = createResource("errors", fetchErrors);
  // const [errorsChannel, { mutate: errorsChannelMutate }] = createResource("errorsChannel", fetchErrorsChannel);
  //
  // const [messages, setMessages] = createSignal([]);
  // const [socket, setSocket] = createSignal(null);
  //
  // const sendLogChannel = async (channelID: string) => {
  //   const res = await fetchAPI("/users/:discordID/guilds/:guildID/servers/:serverID/errors/channels", "POST", {
  //     channelID
  //   });
  //   if (!res.ok) {
  //     return;
  //   }
  //   const screenshot = await res.json();
  //   errorsChannelMutate(screenshot);
  // };
  //
  // const removeLogChannel = async () => {
  //   const res = await fetchAPI("/users/:discordID/guilds/:guildID/servers/:serverID/errors/channels", "DELETE");
  //   if (!res.ok) {
  //     return;
  //   }
  //   errorsChannelMutate({});
  //   return {};
  // };

  // createEffect(() => {
  //   // Initialiser la connexion WebSocket
  //   const ws = new WebSocket(getWSUrl("server_errors", [
  //     "guildID=" + getUrlWithActualParams(":guildID"),
  //     "serverID=" + getUrlWithActualParams(":serverID")
  //   ]));
  //
  //   ws.onopen = () => {
  //     console.log("WebSocket connection opened");
  //   };
  //
  //   ws.onmessage = (event) => {
  //     const newMessage = event.data;
  //     setMessages((prevMessages) => [newMessage, ...prevMessages]);
  //   };
  //
  //   ws.onclose = () => {
  //     console.log("WebSocket connection closed");
  //   };
  //
  //   ws.onerror = (error) => {
  //     console.error("WebSocket error:", error);
  //   };
  //
  //   setSocket(ws);
  //
  //   // Nettoyage à la fermeture du composant
  //   return () => {
  //     ws.close();
  //   };
  // });

  return (
    <>
      {/*<AdminChannelSelector id="select_channel_modal" callback={sendLogChannel} />*/}

      {/*<dialog class="modal" id="selected_player_modal">*/}
      {/*  <div class="modal-box">*/}
      {/*    <form method="dialog">*/}
      {/*      <button class="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">*/}
      {/*        <i class="fas fa-times text-lg"></i>*/}
      {/*      </button>*/}
      {/*    </form>*/}
      {/*    <div class="modal-body flex flex-col gap-4">*/}
      {/*      <h2 class="text-2xl font-bold">*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</dialog>*/}

      {/*<AdminPanel*/}
      {/*  title="Errors Channel"*/}
      {/*  description="Set a channel to sync the errors between your server and Discord."*/}
      {/*>*/}
      {/*  <div class="flex w-fit items-center">*/}
      {/*    <span class="label-text mr-2">Chats Channels:</span>*/}
      {/*    <Show when={!errorsChannel.loading && errorsChannel().channelID}*/}
      {/*          fallback={<span class="label-text">No Errors Channel</span>}>*/}
      {/*      <a*/}
      {/*        href={getUrlWithActualParams(*/}
      {/*          `https://discord.com/channels/:guildID/${errorsChannel().channelID}`*/}
      {/*        )}*/}
      {/*        class="link link-hover text-soft-purple hover:text-soft-purple-active"*/}
      {/*        target="_blank"*/}
      {/*      >*/}
      {/*        {guildChannels().find((channel) => channel.id === errorsChannel().channelID)?.name}*/}
      {/*      </a>*/}
      {/*    </Show>*/}
      {/*  </div>*/}
      {/*  <div class="flex gap-4">*/}
      {/*    <Show when={!errorsChannel.loading && errorsChannel().channelID}>*/}
      {/*      <button*/}
      {/*        class="btn btn-warning"*/}
      {/*        onClick={async () => {*/}
      {/*          await removeLogChannel();*/}
      {/*        }}*/}
      {/*      >*/}
      {/*        Remove Channel*/}
      {/*      </button>*/}
      {/*    </Show>*/}
      {/*    <button*/}
      {/*      class="btn btn-primary"*/}
      {/*      onclick="select_channel_modal.showModal()"*/}
      {/*      onClick={() => guildChannelsRefetch()}*/}
      {/*    >*/}
      {/*      Select Channel*/}
      {/*    </button>*/}
      {/*  </div>*/}
      {/*</AdminPanel>*/}

      <AdminPanel
        title="Errors"
        description="Whatch the errors of your server."
        type="none"
        premium="Limited to 50 last errors for free users."
      >
        <table class="table">
          <thead>
            <tr class="text-white text-l">
              <th>Date</th>
              <th>Addon</th>
              <th>Realm</th>
              <th>Count</th>
              <th>Message</th>
              <th class="w-1/6 text-center">Actions</th>
            </tr>
          </thead>
          <tbody>
            {/*<For each={messages()}>*/}
            {/*  {(errorDataRaw) => {*/}
            {/*    const errorData = JSON.parse(errorDataRaw);*/}
            {/*    return (*/}
            {/*      <AddErrorComponent*/}
            {/*        count={errorData.count} data={errorData} addon={errorData.name}*/}
            {/*        id={errorData.workshopID}*/}
            {/*        realm={errorData.realm} title={errorData.error}*/}
            {/*        createAt={new Date()}*/}
            {/*      />*/}
            {/*    );*/}
            {/*  }}*/}
            {/*</For>*/}
            <Show when={!errors.loading} fallback={<span class="loading loading-lg"></span>}>
              <For each={errors()}>
                {(errorData) => (
                  <AddErrorComponent
                    count={errorData.count}
                    data={errorData}
                    addon={errorData.name}
                    id={errorData.workshopID}
                    realm={errorData.realm}
                    title={errorData.error}
                    createAt={new Date(errorData.createdAt)}
                  />
                )}
              </For>
            </Show>
          </tbody>
        </table>
      </AdminPanel>
    </>
  );
};

export default ServerErrors;
