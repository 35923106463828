import { Component, createResource, createSignal, For, Match, Show, Switch } from "solid-js";
import { fetchAPI, isPremium } from "../../../utils";
import AdminPanel from "../../../components/AdminPanel";
import AdminModal from "../../../components/AdminModal";

class Link {
  id: number;
  alias: string;
  url: string;
  active: boolean;

  constructor(id: number, alias: string, url: string, active: boolean) {
    this.id = id;
    this.alias = alias;
    this.url = url;
    this.active = active;
  }

  setActive(active: boolean) {
    this.active = active;
  }

  setAlias(alias: string) {
    this.alias = alias;
  }

  setUrl(url: string) {
    this.url = url;
  }

  getId() {
    return this.id;
  }

  getAlias() {
    return this.alias;
  }

  getUrl() {
    return this.url;
  }

  isActive() {
    return this.active;
  }
}

const fetchLinks = async () => {
  const linksArray: Link[] = [];
  const res = await fetchAPI("/users/:discordID/guilds/:guildID/links", "GET");
  if (!res.ok) {
    return linksArray;
  }
  const links = await res.json();
  for (const link of links) {
    linksArray.push(new Link(link.id, link.alias, link.url, link.active));
  }
  return linksArray;
};

const GuildLinks: Component = () => {
  const [links, { mutate }] = createResource("links", fetchLinks);
  const [selectedLink, setSelectedLink] = createSignal<Link>(new Link(0, "", "", false));

  const deleteLink = async (link: Link) => {
    const res = await fetchAPI(`/users/:discordID/guilds/:guildID/links/${link.id}`, "DELETE");
    if (!res.ok) {
      return;
    }
    mutate((prevLinks) => (prevLinks ? prevLinks.filter((l) => l.getId() !== link.getId()) : []));
    return link;
  };

  const editLink = async (link: Link) => {
    const res = await fetchAPI(`/users/:discordID/guilds/:guildID/links/${link.id}`, "PUT", {
      alias: link.alias,
      url: link.url,
      active: link.active,
    });
    if (!res.ok) {
      return;
    }
    const updatedLink = await res.json();
    const newLinks = new Link(updatedLink.id, updatedLink.alias, updatedLink.url, updatedLink.active);
    mutate((prevLinks) => (prevLinks ? prevLinks.map((l) => (l.getId() === newLinks.getId() ? newLinks : l)) : []));
    return newLinks;
  };

  const createLink = async () => {
    const newLink = await fetchAPI(`/users/:discordID/guilds/:guildID/links`, "POST");
    if (!newLink.ok) {
      return;
    }
    const link = await newLink.json();
    const newLinkObj = new Link(link.id, link.alias, link.url, link.active);
    mutate((prevLinks) => (prevLinks ? [...prevLinks, newLinkObj] : [newLinkObj]));
    return newLinkObj;
  };

  return (
    <>
      {/*<PremiumFeature message="The free plan only allows you to create 2 links." />*/}
      <AdminModal title="Edit Link" id="edit_select_link">
        <div class="form-control">
          <label class="label">
            <span class="label-text">Alias</span>
          </label>
          <input
            type="text"
            class="input input-bordered"
            value={selectedLink().getAlias()}
            onInput={(e) => {
              selectedLink().setAlias(e.currentTarget.value);
            }}
          />
        </div>

        <div class="form-control">
          <label class="label">
            <span class="label-text">URL</span>
          </label>
          <input
            type="text"
            class="input input-bordered"
            value={selectedLink().getUrl()}
            onInput={(e) => {
              selectedLink().setUrl(e.currentTarget.value);
            }}
          />
        </div>

        <div class="form-control">
          <label class="label">
            <span class="label-text">Active</span>
          </label>
          <select
            class="select select-bordered"
            value={selectedLink().isActive() ? "true" : "false"}
            onChange={(e) => {
              selectedLink().setActive(e.currentTarget.value === "true");
            }}
          >
            <option value="true">Yes</option>
            <option value="false">No</option>
          </select>
        </div>

        <button
          class="btn btn-primary mt-2"
          onclick="edit_select_link.close()"
          onClick={async () => {
            await editLink(selectedLink());
          }}
        >
          Save
        </button>
      </AdminModal>

      <AdminPanel
        title="Links"
        description="Create shortcuts for your server informations."
        type="none"
        premium="Limited to 2 links for free users."
      >
        <table class="table">
          <thead>
            <tr class="text-white text-l">
              <th>Alias</th>
              <th>URL</th>
              <th class="w-1/6 text-center">Active</th>
              <th class="w-1/6 text-center">Actions</th>
            </tr>
          </thead>
          <tbody>
            <Show when={!links.loading}>
              <For each={links()}>
                {(link) => (
                  <tr>
                    <td>{link.alias}</td>
                    <td>{link.url}</td>
                    <td>
                      <div class="flex gap-2 justify-center">
                        {link.active ? <i class="fas fa-check text-green"></i> : <i class="fas fa-times text-red"></i>}
                      </div>
                    </td>
                    <td>
                      <div class="flex gap-2 justify-center">
                        <div class="tooltip tooltip-info" data-tip="Edit">
                          <i
                            class="hover:cursor-pointer fas fa-edit"
                            onclick="edit_select_link.showModal()"
                            onClick={() => {
                              setSelectedLink(link);
                            }}
                          ></i>
                        </div>
                        <div class="tooltip tooltip-error" data-tip="Delete">
                          <i class="hover:cursor-pointer fas fa-trash text-red" onClick={() => deleteLink(link)}></i>
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
              </For>
            </Show>
          </tbody>
        </table>

        <Switch>
          <Match when={links.loading}>
            <div class="flex justify-center h-36">
              <div class="loading loading-spinner loading-lg"></div>
            </div>
          </Match>
          <Match when={links.error}>
            <div>Failed to load the links</div>
          </Match>
        </Switch>

        <Show when={!links.loading}>
          <div class="flex gap-4 p-4">
            <button
              class="btn btn-primary"
              classList={{
                "btn-disabled": !isPremium() && (!links() || links().length >= 2),
              }}
              onClick={async () => {
                await createLink();
              }}
            >
              Add Link
            </button>
          </div>
        </Show>
      </AdminPanel>
    </>
  );
};

export default GuildLinks;
