import { Component, createResource, createSignal, onCleanup, Show } from "solid-js";
import AdminPanel from "../../../components/AdminPanel";
import { fetchAPI, getUrlWithActualParams } from "../../../utils";
import { A } from "@solidjs/router";
import AdminModal from "../../../components/AdminModal";
import { AddError } from "../../../index";

const GuildBot: Component = () => {
  const [showToken, setShowToken] = createSignal(false);
  const [bot, { mutate: setBot, refetch: refreshBot }] = createResource("bot", async () => {
    return await fetchAPI("/users/:discordID/guilds/:guildID/bot", "GET").then(async (res) => {
      if (!res.ok) throw new Error("An error occurred while fetching the bot.");
      return await res.json();
    });
  });

  async function saveBot() {
    const res = await fetchAPI("/users/:discordID/guilds/:guildID/bot", "PUT", bot());
    if (!res.ok) return AddError("An error occurred while saving the bot.");
    const data = await res.json();
    setBot(data);
  }

  async function setupBot() {
    const res = await fetchAPI("/users/:discordID/guilds/:guildID/bot", "PATCH", {
      token: bot().token,
    });
    if (!res.ok) return AddError("The given token is invalid or intents are missing.");
    const data = await res.json();
    setBot(data);
  }

  function getNameOf(id: string) {
    const names: Record<string, string> = {
      disabled: "Disabled",
      guildMemberCount: "Show Guild Member Count",
      playerCount: "Show Player Count",
      rotate: "Alternate Showed Information",
    };

    return names[id] || "Unknown";
  }

  return (
    <>
      <Show when={!bot.loading && !bot().custom}>
        <div class="text-indigo-300 flex h-12 items-center rounded-lg border-indigo-400 border p-4 gap-4">
          {/*<i class="fas fa-crown"></i>*/}
          <i class="fa-solid fa-robot"></i>
          <span>
            {"This feature is only available for GmodStore's custom bots."}{" "}
            <A class="link" href="/gmodstore">
              Get a Custom Bot
            </A>
          </span>
        </div>
      </Show>

      <AdminModal title="Edit Custom Bot" id="edit_bot">
        <Show when={!bot.loading}>
          {/* name */}
          <div class="form-control">
            <label class="label">
              <span class="label-text">Name</span>
            </label>
            <input
              type="text"
              class="input input-bordered"
              value={bot().username}
              onChange={(e) => setBot({ ...bot(), username: e.currentTarget.value })}
            />
          </div>

          {/*avatar*/}
          <div class="form-control">
            <label class="label">
              <span class="label-text">Avatar</span>
            </label>
            <input
              type="text"
              class="input input-bordered"
              value={bot().avatar}
              onChange={(e) => setBot({ ...bot(), avatar: e.currentTarget.value })}
            />
          </div>

          <div class="form-control">
            <label class="label">
              <span class="label-text">Status</span>
              <span class="label-text text-rose-500">Refresh every 30s</span>
            </label>
            <select
              class="select select-bordered w-full"
              onChange={(e) => setBot({ ...bot(), status: e.currentTarget.value })}
            >
              <option value="disabled" selected={bot().status == "disabled"}>
                {getNameOf("disabled")}
              </option>
              <option value="guildMemberCount" selected={bot().status == "guildMemberCount"}>
                {getNameOf("guildMemberCount")}
              </option>
              <option value="playerCount" selected={bot().status == "playerCount"}>
                {getNameOf("playerCount")}
              </option>
              <option value="rotate" selected={bot().status == "rotate"}>
                {getNameOf("rotate")}
              </option>
            </select>
          </div>

          <button
            class="btn btn-primary mt-2"
            onClick="edit_bot.close()"
            onClick={async () => {
              await saveBot();
            }}
          >
            Save
          </button>
        </Show>
      </AdminModal>

      <AdminModal title="Setup Custom Bot" id="setup_bot">
        <Show when={!bot.loading}>
          {/*short how to (create a discord.dev bot active 3 intent copy token*/}

          {/**/}
          <div class="form-control p-2">
            <h1 class="text-lg text-gray-300 font-bold">How to setup a custom bot:</h1>
            <ul class="list-decimal text-gray-400 list-inside">
              <li>
                Create a app on{" "}
                <A class="link" href="https://discord.com/developers/applications">
                  discord.dev
                </A>
              </li>
              <li>Go to the bot tab</li>
              <li>Enable 'Presence Intent'</li>
              <li>Enable 'Server Members Intent'</li>
              <li>Enable 'Message Content Intent'</li>
              <li>Reset the token</li>
              <li>Copy the token</li>
            </ul>
          </div>

          {/*set token*/}
          <div class="form-control">
            <label class="label">
              <span class="label-text">Token</span>
            </label>
            <input
              type="text"
              class="input input-bordered"
              value={bot().token}
              onChange={(e) => setBot({ ...bot(), token: e.currentTarget.value })}
            />
          </div>

          <button
            class="btn btn-primary mt-2"
            onClick="setup_bot.close()"
            onClick={async () => {
              await setupBot();
            }}
          >
            Save
          </button>
        </Show>
      </AdminModal>

      <AdminPanel title="Custom Bot" description="Edit the bot information.">
        {/* name */}
        <div class="flex w-fit items-center">
          <span class="label-text mr-2 text-nowrap">Name :</span>
          <span class="label-text">{!bot.loading ? bot().username : "Loading..."}</span>
        </div>

        {/* avatar */}
        <div class="flex w-fit items-center">
          <span class="label-text mr-2 text-nowrap">Avatar :</span>
          <img src={!bot.loading ? bot().avatar : ""} alt="Avatar" class="w-8 h-8 rounded-full" />
        </div>

        <div class="flex w-fit items-center">
          <span class="label-text mr-2 text-nowrap">Status :</span>
          <span class="label-text">{!bot.loading ? getNameOf(bot().status) : "Loading..."}</span>
        </div>

        <Show when={!bot.loading && bot().purchased}>
          <div class="flex w-fit items-center gap-4">
            <button
              class="btn btn-primary"
              onClick="edit_bot.showModal()"
              classList={{
                hidden: !bot().custom || !bot().onGuild,
              }}
            >
              Edit Custom Bot
            </button>
            <button
              onClick={() => {
                const newWindow = window.open(
                  getUrlWithActualParams(
                    `https://discord.com/oauth2/authorize?client_id=${bot().id}&permissions=1759218604441591&scope=bot&guild_id=:guildID`,
                  ),
                  "_blank",
                  "width=600,height=900",
                );
                const timer = setInterval(function () {
                  if (newWindow && newWindow.closed) {
                    clearInterval(timer);
                    refreshBot();
                  }
                }, 500);
                onCleanup(() => clearInterval(timer));
              }}
              class="btn btn-primary"
              classList={{
                hidden: bot().onGuild || !bot().custom,
              }}
            >
              Join Guild
            </button>
            <button
              class="btn btn-primary"
              onClick="setup_bot.showModal()"
              classList={{
                hidden: !bot().active,
              }}
            >
              Setup Custom Bot
            </button>
          </div>
        </Show>
      </AdminPanel>
    </>
  );
};

export default GuildBot;
