import { Component, createResource, createSignal, For, Match, Show, Switch } from "solid-js";
import { fetchAPI, getUrlWithActualParams, isPremium } from "../../../../utils";
import AdminPanel from "../../../../components/AdminPanel";
import AdminModal from "../../../../components/AdminModal";
import "emoji-picker-element";
import { guildChannelsRefetch } from "../GuildInformations";
import AdminChannelSelector from "../../../../components/AdminChannelSelector";

const fetchStatusButtons = async () => {
  const res = await fetchAPI("/users/:discordID/guilds/:guildID/servers/:serverID/status/buttons", "GET");
  if (!res.ok) {
    return {};
  }
  return await res.json();
};

const fetchStatus = async () => {
  const res = await fetchAPI("/users/:discordID/guilds/:guildID/servers/:serverID/status", "GET");
  if (!res.ok) {
    return {};
  }
  return await res.json();
};

const fetchShowPlayerList = async () => {
  const res = await fetchAPI(
    "/users/:discordID/guilds/:guildID/servers/:serverID/settings/show_player_list_status",
    "GET",
  );
  if (!res.ok) {
    return {};
  }
  return await res.json();
};

//show_player_list_status
class StatusButton {
  id: number;
  emoji: string;
  name: string;
  url: string;
  enable: boolean;

  constructor(id: number, emoji: string, name: string, url: string, enable: boolean) {
    this.id = id;
    this.emoji = emoji;
    this.name = name;
    this.url = url;
    this.enable = enable;
  }
}

const ServerStatus: Component = () => {
  const [statusButtons, { mutate }] = createResource("statusButtons", fetchStatusButtons);
  const [selectStatusButton, setSelectStatusButton] = createSignal(new StatusButton(0, "", "", "", false));
  const [visibleEmojiPicker, setVisibleEmojiPicker] = createSignal(false);
  const [status, { mutate: statusMutate }] = createResource("status", fetchStatus);
  const [showPlayerList, { mutate: showPlayerListMutate }] = createResource("showPlayerList", fetchShowPlayerList);

  const sendStatus = async (channelID: string) => {
    const res = await fetchAPI("/users/:discordID/guilds/:guildID/servers/:serverID/status", "POST", {
      channelID,
    });
    if (!res.ok) {
      return;
    }
    const status = await res.json();
    statusMutate(status);
    return status;
  };

  const removeStatus = async () => {
    const res = await fetchAPI("/users/:discordID/guilds/:guildID/servers/:serverID/status", "DELETE");
    if (!res.ok) {
      return;
    }
    statusMutate({});
  };

  const createStatusButton = async () => {
    const res = await fetchAPI("/users/:discordID/guilds/:guildID/servers/:serverID/status/buttons", "POST");
    if (!res.ok) {
      return;
    }
    const button = await res.json();
    mutate((prevButtons) =>
      prevButtons
        ? [...prevButtons, new StatusButton(button.id, button.emoji, button.name, button.url, button.enable)]
        : [],
    );
    return button;
  };

  const deleteStatusButton = async (button: StatusButton) => {
    const res = await fetchAPI(
      `/users/:discordID/guilds/:guildID/servers/:serverID/status/buttons/${button.id}`,
      "DELETE",
    );
    if (!res.ok) {
      return;
    }
    mutate((prevButtons) => prevButtons?.filter((b) => b.id !== button.id));
  };

  const editStatusButton = async (button: StatusButton) => {
    const res = await fetchAPI(
      `/users/:discordID/guilds/:guildID/servers/:serverID/status/buttons/${button.id}`,
      "PUT",
      button,
    );
    if (!res.ok) {
      return;
    }
    const newButton = await res.json();
    mutate((prevButtons) => prevButtons?.map((b) => (b.id === newButton.id ? newButton : b)));
    return newButton;
  };

  const handleEmojiClick = (event: CustomEvent) => {
    setSelectStatusButton({ ...selectStatusButton(), emoji: event.detail.unicode });
    setVisibleEmojiPicker(false);
  };

  async function editPlayerListMutate(value: boolean) {
    fetchAPI("/users/:discordID/guilds/:guildID/servers/:serverID/settings/show_player_list_status", "PUT", {
      value: value,
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error("An error occurred while updating the pseudo direction.");
        }
      })
      .then((data) => {
        showPlayerListMutate(data);
      });
  }

  // @ts-ignore
  return (
    <>
      <AdminChannelSelector id="select_channel_modal" callback={sendStatus} />

      <AdminModal title="Edit Button" id="edit_status_button">
        <div class="form-control">
          <label class="label">
            <span class="label-text">Emoji</span>
          </label>
          <button class="input input-bordered text-left" onClick={() => setVisibleEmojiPicker(!visibleEmojiPicker())}>
            {selectStatusButton().emoji}
          </button>
          <Show when={visibleEmojiPicker()}>
            {/*// @ts-ignore*/}
            <emoji-picker emoji-version="12.0" onEmoji-click={handleEmojiClick}></emoji-picker>
          </Show>
        </div>

        <div class="form-control">
          <label class="label">
            <span class="label-text">Label</span>
          </label>
          <input
            type="text"
            class="input input-bordered"
            value={selectStatusButton().name}
            onChange={(e) => setSelectStatusButton({ ...selectStatusButton(), name: e.currentTarget.value })}
          />
        </div>

        <div class="form-control">
          <label class="label">
            <span class="label-text">URL</span>
          </label>
          <input
            type="text"
            class="input input-bordered"
            value={selectStatusButton().url}
            onChange={(e) => setSelectStatusButton({ ...selectStatusButton(), url: e.currentTarget.value })}
          />
        </div>

        <div class="form-control">
          <label class="label">
            <span class="label-text">Active</span>
          </label>
          <select
            class="select select-bordered"
            value={selectStatusButton().enable ? "true" : "false"}
            onChange={(e) => {
              selectStatusButton().enable = e.currentTarget.value === "true";
            }}
          >
            <option value="true">Yes</option>
            <option value="false">No</option>
          </select>
        </div>

        <button
          class="btn btn-primary mt-2"
          onclick="edit_status_button.close()"
          onClick={async () => {
            await editStatusButton(selectStatusButton());
          }}
        >
          Save
        </button>
      </AdminModal>

      <AdminPanel title="Status" description="Display a beautiful status message for your server.">
        <div class="flex w-fit items-center">
          <span class="label-text mr-2">Status Message:</span>
          <Show when={!status.loading && status().channel} fallback={<span class="label-text">No status message</span>}>
            <a
              href={getUrlWithActualParams(
                `https://discord.com/channels/:guildID/${status().channel}/${status().message}`,
              )}
              class="link link-hover text-soft-purple hover:text-soft-purple-active"
              target="_blank"
            >
              {status().channel}
            </a>
          </Show>
        </div>
        <div class="flex w-fit items-center">
          <span class="label-text mr-2">Show Player List:</span>
          <input
            type="checkbox"
            class="toggle toggle-md"
            checked={!showPlayerList.loading ? (showPlayerList() ? showPlayerList().value : false) : false}
            onChange={async (e) => {
              await editPlayerListMutate(e.currentTarget.checked);
            }}
          />
        </div>
        <div class="flex gap-4">
          <Show when={!status.loading && status().channel}>
            <button
              class="btn btn-warning"
              onClick={async () => {
                await removeStatus();
              }}
            >
              Remove Status
            </button>
          </Show>
          <button
            class="btn btn-primary"
            onclick="select_channel_modal.showModal()"
            onClick={() => guildChannelsRefetch()}
          >
            Send Status
          </button>
        </div>
      </AdminPanel>

      <AdminPanel
        title="Status Buttons"
        description="Add utility buttons to your server status message."
        type="none"
        premium="Limited to 3 buttons for free users."
      >
        <table class="table">
          <thead>
            <tr class="text-white text-l">
              <th>Emoji</th>
              <th>Label</th>
              <th>URL</th>
              <th class="w-1/6 text-center">Active</th>
              <th class="w-1/6 text-center">Actions</th>
            </tr>
          </thead>
          <tbody>
            <Show when={!statusButtons.loading}>
              <For each={statusButtons()}>
                {(button) => (
                  <tr>
                    <td>{button.emoji}</td>
                    <td>{button.name}</td>
                    <td>{button.url}</td>
                    <td>
                      <div class="flex gap-2 justify-center">
                        {button.enable ? (
                          <i class="fas fa-check text-green"></i>
                        ) : (
                          <i class="fas fa-times text-red"></i>
                        )}
                      </div>
                    </td>
                    <td>
                      <div class="flex gap-2 justify-center">
                        <div class="tooltip tooltip-info" data-tip="Edit">
                          <i
                            class="hover:cursor-pointer fas fa-edit"
                            onclick="edit_status_button.showModal()"
                            onClick={() => setSelectStatusButton(button)}
                          ></i>
                        </div>
                        <div class="tooltip tooltip-error" data-tip="Delete">
                          <i
                            class="hover:cursor-pointer fas fa-trash text-red"
                            onClick={() => deleteStatusButton(button)}
                          ></i>
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
              </For>
            </Show>
          </tbody>
        </table>

        <Switch>
          <Match when={statusButtons.loading}>
            <div class="flex justify-center h-36">
              <div class="loading loading-spinner loading-lg"></div>
            </div>
          </Match>
          <Match when={statusButtons.error}>
            <tr>
              <td colSpan="4">Failed to load the links</td>
            </tr>
          </Match>
        </Switch>

        <div class="flex gap-4 p-4">
          <button
            class="btn btn-primary"
            onClick={() => createStatusButton()}
            disabled={!isPremium() && (!statusButtons() || statusButtons().length >= 3)}
          >
            Add Button
          </button>
        </div>
      </AdminPanel>
    </>
  );
};

export default ServerStatus;
