import { Component, createEffect, createResource, createSignal, For, Match, Show, Switch } from "solid-js";
import AdminPanel from "../../../../components/AdminPanel";
import { fetchAPI, getUrlWithActualParams, getWSUrl } from "../../../../utils";
import AdminChannelSelector from "../../../../components/AdminChannelSelector";
import { guildChannels, guildChannelsRefetch } from "../GuildInformations";

const fetchLogs = async () => {
  const res = await fetchAPI("/users/:discordID/guilds/:guildID/servers/:serverID/logs", "GET");
  if (!res.ok) {
    return {};
  }
  return await res.json();
};

const fetchLogHideIP = async () => {
  const res = await fetchAPI("/users/:discordID/guilds/:guildID/servers/:serverID/settings/log_hide_ip", "GET");
  if (!res.ok) {
    return {};
  }
  return await res.json();
};

const fetchLogFile = async () => {
  const res = await fetchAPI("/users/:discordID/guilds/:guildID/servers/:serverID/settings/log_include_file", "GET");
  if (!res.ok) {
    return {};
  }
  return await res.json();
};

const fetchLogsChannel = async () => {
  const res = await fetchAPI("/users/:discordID/guilds/:guildID/servers/:serverID/logs/channels", "GET");
  if (!res.ok) {
    return {};
  }
  return await res.json();
};

interface AddLog {
  data: Object;
  category: string;
  createAt: Date;
}

const typeFormat = {
  playerName: {
    class: "text-yellow-400",
  },
  stringPrimary: {
    class: "text-teal-200",
  },
};

const GetFormat: Component = (props: Object) => {
  return (
    <>
      <span class={typeFormat[props.type].class}>{props.text}</span>
    </>
  );
};

const PlayerComponent: Component = (props: Object) => {
  return (
    <>
      {/* name (orange) */}
      <GetFormat type="playerName" text={props.ply ? props.ply.name : "Unknown"} />
    </>
  );
};

const logColors: any = {
  player_connect: "#cd8f51",
  player_disconnect: "#cd8f51",
  player_death: "#cd5151",
  player_spawn: "#51cd51",
  player_ready: "#51cd51",
  player_change_group: "#cd51bc",
  player_change_name: "#cd51bc",
  player_give: "#cdc751",
  player_spawn_object: "#68c13c",
  player_hurt: "#cd5151",
  player_initial_spawn: "#51cd51",
  player_say: "#51c3cd",
  server_start: "#6575d5",
  server_stop: "#6575d5",
};

const logNames: any = {
  player_connect: "Player Connect",
  player_disconnect: "Player Disconnect",
  player_death: "Player Death",
  player_spawn: "Player Spawn",
  player_ready: "Player Ready",
  player_change_group: "Player Change Group",
  player_change_name: "Player Change Name",
  player_say: "Player Say",
  player_give: "Player Give",
  player_spawn_object: "Player Spawn Object",
  player_hurt: "Player Hurt",
  player_initial_spawn: "Player Initial Spawn",
  server_start: "Server Start",
  server_stop: "Server Stop",
};

const [selectLog, setSelectLog] = createSignal(0);
let idxLog = 0;
const AddLogComponent: Component<AddLog> = (props) => {
  const logContentStr = JSON.stringify(props.data, null, 2).split("\n");
  idxLog++;
  const localIdxLog = idxLog;
  return (
    <>
      <tr>
        <td class="text-gray-300">{props.createAt.toLocaleString()}</td>
        <td class="text-white" style={`color: ${logColors[props.category] || "#fff"}`}>
          {logNames[props.category] || props.category}
        </td>
        <td>
          <Switch fallback={<span>{JSON.stringify(props.data)}</span>}>
            <Match when={props.category === "player_say"}>
              <span>
                <PlayerComponent ply={props.data.ply} /> say <GetFormat type="stringPrimary" text={props.data.text} />
              </span>
            </Match>
            <Match when={props.category === "player_disconnect"}>
              <span>
                <PlayerComponent ply={props.data.ply} /> has disconnected
              </span>
            </Match>
            <Match when={props.category === "player_spawn"}>
              <span>
                <PlayerComponent ply={props.data.ply} /> has spawned
              </span>
            </Match>
            <Match when={props.category === "player_death"}>
              <span>
                <PlayerComponent ply={props.data.plyTarget} /> has been killed by{" "}
                <PlayerComponent ply={props.data.plyAttacker} />
              </span>
            </Match>
            <Match when={props.category === "player_ready"}>
              <span>
                <PlayerComponent ply={props.data.ply} /> is ready
              </span>
            </Match>
            <Match when={props.category === "player_connect"}>
              <span>
                <GetFormat type="playerName" text={props.data.name} /> is connecting from{" "}
                <GetFormat type="stringPrimary" text={props.data.ip} />
              </span>
            </Match>
            <Match when={props.category === "player_change_group"}>
              <span>
                <PlayerComponent ply={props.data.ply} /> has changed group from{" "}
                <GetFormat type="stringPrimary" text={props.data.oldGroup} /> to{" "}
                <GetFormat type="stringPrimary" text={props.data.newGroup} />
              </span>
            </Match>
            <Match when={props.category === "player_change_name"}>
              <span>
                <PlayerComponent ply={props.data.ply} /> has changed name from{" "}
                <GetFormat type="stringPrimary" text={props.data.oldName} /> to{" "}
                <GetFormat type="stringPrimary" text={props.data.newName} />
              </span>
            </Match>
            <Match when={props.category === "player_give"}>
              <span>
                <PlayerComponent ply={props.data.ply} /> get{" "}
                <GetFormat type="stringPrimary" text={props.data.wep_class} />
              </span>
            </Match>
            <Match when={props.category === "player_spawn_object"}>
              <span>
                <PlayerComponent ply={props.data.ply} /> has spawned
                <GetFormat type="stringPrimary" text={" " + props.data.object + " "} />(
                <Show
                  when={props.data.object === "prop"}
                  fallback={<GetFormat type="stringPrimary" text={props.data.entity ? props.data.entity.class : ""} />}
                >
                  <GetFormat type="stringPrimary" text={props.data.model} />
                </Show>
                )
              </span>
            </Match>
            <Match when={props.category === "player_hurt"}>
              <span>
                <PlayerComponent ply={props.data.ply} /> has been hurt by <PlayerComponent ply={props.data.attacker} />
                for <GetFormat type="stringPrimary" text={props.data.damage} /> damage
              </span>
            </Match>
            <Match when={props.category === "player_initial_spawn"}>
              <span>
                <PlayerComponent ply={props.data.ply} /> has spawned for the first time
              </span>
            </Match>
            <Match when={props.category === "server_start" || props.category === "server_stop"}>
              <></>
            </Match>
          </Switch>
        </td>
        <td>
          <div class="flex gap-2 justify-center">
            <div
              class="tooltip tooltip-info"
              data-tip="Show More"
              onClick={() => {
                if (selectLog() === localIdxLog) {
                  setSelectLog(0);
                } else {
                  setSelectLog(localIdxLog);
                }
              }}
            >
              <Show when={selectLog() === localIdxLog}>
                <i class="fas fa-chevron-up"></i>
              </Show>
              <Show when={selectLog() !== localIdxLog}>
                <i class="fas fa-chevron-down"></i>
              </Show>
            </div>
            <div class="tooltip tooltip-info" data-tip="Download">
              <a
                href={`data:text/plain;charset=utf-8,${encodeURIComponent(logContentStr.join("\n"))}`}
                download={`log-${props.createAt.toLocaleString()}.json`}
              >
                <i class="fas fa-download"></i>
              </a>
            </div>
          </div>
        </td>
      </tr>

      <Show when={selectLog() === localIdxLog}>
        <tr>
          <td colspan="4">
            <pre>
              <code>{JSON.stringify(props.data, null, 2)}</code>
            </pre>
          </td>
        </tr>
      </Show>
    </>
  );
};

const ServerLogs: Component = () => {
  const [logs, { mutate: logsMutate }] = createResource("logs", fetchLogs);
  const [logsChannel, { mutate: logsChannelMutate }] = createResource("logsChannel", fetchLogsChannel);
  const [logHideIP, { mutate: logHideIPMutate }] = createResource("logHideIP", fetchLogHideIP);
  const [logFile, { mutate: logFileMutate }] = createResource("logFile", fetchLogFile);

  const [messages, setMessages] = createSignal([]);
  const [socket, setSocket] = createSignal(null);

  const sendLogChannel = async (channelID: string) => {
    const res = await fetchAPI("/users/:discordID/guilds/:guildID/servers/:serverID/logs/channels", "POST", {
      channelID,
    });
    if (!res.ok) {
      return;
    }
    const screenshot = await res.json();
    logsChannelMutate(screenshot);
  };

  const removeLogChannel = async () => {
    const res = await fetchAPI("/users/:discordID/guilds/:guildID/servers/:serverID/logs/channels", "DELETE");
    if (!res.ok) {
      return;
    }
    logsChannelMutate({});
    return {};
  };

  async function editLogHideIP(value: boolean) {
    fetchAPI("/users/:discordID/guilds/:guildID/servers/:serverID/settings/log_hide_ip", "PUT", {
      value: value,
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error("An error occurred while updating the pseudo direction.");
        }
      })
      .then((data) => {
        logHideIPMutate(data);
      });
  }

  async function editLogFile(value: boolean) {
    fetchAPI("/users/:discordID/guilds/:guildID/servers/:serverID/settings/log_include_file", "PUT", {
      value: value,
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error("An error occurred while updating the pseudo direction.");
        }
      })
      .then((data) => {
        logFileMutate(data);
      });
  }

  createEffect(() => {
    // Initialiser la connexion WebSocket
    const ws = new WebSocket(
      getWSUrl("server_logs", [
        "guildID=" + getUrlWithActualParams(":guildID"),
        "serverID=" + getUrlWithActualParams(":serverID"),
      ]),
    );

    ws.onopen = () => {
      console.log("WebSocket connection opened");
    };

    ws.onmessage = (event) => {
      const newMessage = event.data;
      setMessages((prevMessages) => [newMessage, ...prevMessages]);
    };

    ws.onclose = () => {
      console.log("WebSocket connection closed");
    };

    ws.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    setSocket(ws);

    // Nettoyage à la fermeture du composant
    return () => {
      ws.close();
    };
  });

  // const sendMessage = (message) => {
  //   if (socket()) {
  //     socket().send(message);
  //   }
  // };

  return (
    <>
      <AdminChannelSelector id="select_channel_modal" callback={sendLogChannel} />

      {/*<dialog class="modal" id="selected_player_modal">*/}
      {/*  <div class="modal-box">*/}
      {/*    <form method="dialog">*/}
      {/*      <button class="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">*/}
      {/*        <i class="fas fa-times text-lg"></i>*/}
      {/*      </button>*/}
      {/*    </form>*/}
      {/*    <div class="modal-body flex flex-col gap-4">*/}
      {/*      <h2 class="text-2xl font-bold">*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</dialog>*/}

      <AdminPanel title="Logs Channel" description="Set a channel to sync the logs between your server and Discord.">
        <div class="flex w-fit items-center">
          <span class="label-text mr-2">Chats Channels:</span>
          <Show
            when={!logsChannel.loading && logsChannel().channelID}
            fallback={<span class="label-text">No Logs Channel</span>}
          >
            <a
              href={getUrlWithActualParams(`https://discord.com/channels/:guildID/${logsChannel().channelID}`)}
              class="link link-hover text-soft-purple hover:text-soft-purple-active"
              target="_blank"
            >
              {guildChannels().find((channel) => channel.id === logsChannel().channelID)?.name}
            </a>
          </Show>
        </div>
        <Show when={!logHideIP.loading}>
          <div class="flex w-fit items-center">
            <span class="label-text mr-2">Hide IP in discord logs:</span>
            <input
              type="checkbox"
              class="toggle toggle-md"
              checked={logHideIP() ? logHideIP().value : false}
              onChange={async (e) => {
                await editLogHideIP(e.currentTarget.checked);
              }}
            />
          </div>
        </Show>
        <Show when={!logFile.loading}>
          <div class="flex w-fit items-center">
            <span class="label-text mr-2">Attach log information file:</span>
            <input
              type="checkbox"
              class="toggle toggle-md"
              checked={logFile() ? logFile().value : false}
              onChange={async (e) => {
                await editLogFile(e.currentTarget.checked);
              }}
            />
          </div>
        </Show>
        <div class="flex gap-4">
          <Show when={!logsChannel.loading && logsChannel().channelID}>
            <button
              class="btn btn-warning"
              onClick={async () => {
                await removeLogChannel();
              }}
            >
              Remove Channel
            </button>
          </Show>
          <button
            class="btn btn-primary"
            onclick="select_channel_modal.showModal()"
            onClick={() => guildChannelsRefetch()}
          >
            Select Channel
          </button>
        </div>
      </AdminPanel>

      <AdminPanel
        title="Logs"
        description="Whatch the logs of your server."
        type="none"
        premium="Limited to 500 last logs for free users."
      >
        <table class="table">
          <thead>
            <tr class="text-white text-l">
              <th class="w-1/5">Date</th>
              <th class="w-1/5">Category</th>
              <th>Log</th>
              <th class="w-1/6 text-center">Actions</th>
            </tr>
          </thead>
          <tbody>
            <For each={messages()}>
              {(msg) => {
                const parsedMsg = JSON.parse(msg);
                return <AddLogComponent data={parsedMsg.data} category={parsedMsg.type} createAt={new Date()} />;
              }}
            </For>
            <Show when={!logs.loading} fallback={<span class="loading loading-lg"></span>}>
              <For each={logs()}>
                {(log) => <AddLogComponent data={log.data} category={log.type} createAt={new Date(log.createdAt)} />}
              </For>
            </Show>
          </tbody>
        </table>
      </AdminPanel>
    </>
  );
};

export default ServerLogs;
