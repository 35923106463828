import { Component, createResource, createSignal, For, Match, Show, Switch } from "solid-js";
import { fetchAPI, isPremium } from "../../../utils";
import { guildRoles, guildRolesRefetch } from "./GuildInformations";
import AdminPanel from "../../../components/AdminPanel";
import AdminModal from "../../../components/AdminModal";
import AdminChannelSelector from "../../../components/AdminChannelSelector";

const fetchVerifyRoles = async () => {
  const res = await fetchAPI("/users/:discordID/guilds/:guildID/auto-roles", "GET");
  if (!res.ok) {
    return {};
  }
  return await res.json();
};

const fetchNotVerifyRoles = async () => {
  const res = await fetchAPI("/users/:discordID/guilds/:guildID/verifications/roles/", "GET");
  if (!res.ok) {
    return {};
  }
  return await res.json();
};

const GuildAutoRole: Component = () => {
  const [autoRoles, { mutate }] = createResource("verifyRole", fetchVerifyRoles);
  const [selectedRole, setSelectedRole] = createSignal({});

  const deleteAutoRole = async (role: string) => {
    const res = await fetchAPI(`/users/:discordID/guilds/:guildID/auto-roles/${role.roleID}`, "DELETE");
    if (!res.ok) {
      return;
    }
    mutate((prevVerifyRole) => (prevVerifyRole ? prevVerifyRole.filter((v) => v.roleID !== role.roleID) : []));
    return role;
  };

  const createVerifyRole = async (roleID: string) => {
    const newLink = await fetchAPI(`/users/:discordID/guilds/:guildID/auto-roles/${roleID}`, "POST");
    if (!newLink.ok) {
      return;
    }
    const verifyRole = await newLink.json();
    mutate((prevVerifyRole) => (prevVerifyRole ? [...prevVerifyRole, verifyRole] : [verifyRole]));
    return verifyRole;
  };

  return (
    <>
      <AdminModal title="Select Role" id="select_role_modal">
        <Show when={!guildRoles.loading && !autoRoles.loading} fallback={<div>Loading...</div>}>
          <div class="form-control">
            <select
              class="select select-bordered"
              onchange="select_role_modal.close()"
              onChange={async (e) => {
                await createVerifyRole(e.currentTarget.value);
              }}
            >
              <option value="0">Select a Role</option>
              <For each={guildRoles()}>
                {(role) => {
                  // if
                  if (!autoRoles().find((v) => v.roleID === role.id)) {
                    return <option value={role.id}>{role.name}</option>;
                  }
                }}
              </For>
            </select>
          </div>
        </Show>
      </AdminModal>

      <AdminChannelSelector id="select_channel_modal" />

      <AdminModal title="Edit Role" id="edit_role_modal">
        <Show when={!guildRoles.loading && !autoRoles.loading}>
          <div class="form-control">
            <label class="label">
              <span class="label-text">Role</span>
            </label>
            <select class="select select-bordered" disabled>
              <option selected>{guildRoles().find((r) => r.id === selectedRole().roleID)?.name}</option>
            </select>
          </div>
          <div class="form-control">
            <label class="label">
              <span class="label-text">Active</span>
            </label>
            <select
              class="select select-bordered"
              value={selectedRole().enabled ? "true" : "false"}
              onChange={(e) => {
                selectedRole().enabled = e.currentTarget.value === "true";
              }}
            >
              <option value="true">Yes</option>
              <option value="false">No</option>
            </select>
          </div>
          <button
            class="btn btn-primary mt-2"
            onclick="edit_role_modal.close()"
            onClick={async () => {
              await editVerifyRole(selectedRole());
            }}
          >
            Save
          </button>
        </Show>
      </AdminModal>

      <AdminPanel
        title="Auto Roles"
        description="Here you can set roles to give by default for new members."
        type="none"
        premium="Limited to 3 auto roles for free users."
      >
        <table class="table">
          <thead>
            <tr class="text-white text-l">
              <th>Role</th>
              <th class="w-1/6 text-center">Actions</th>
            </tr>
          </thead>
          <tbody>
            <Show when={!autoRoles.loading}>
              <For each={autoRoles()}>
                {(role) => (
                  <tr>
                    <td>{guildRoles().find((r) => r.id === role.roleID)?.name}</td>
                    <td>
                      <div class="flex gap-2 justify-center">
                        <div class="tooltip tooltip-error" data-tip="Delete">
                          <i
                            class="hover:cursor-pointer fas fa-trash text-red"
                            onClick={() => deleteAutoRole(role)}
                          ></i>
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
              </For>
            </Show>
          </tbody>
        </table>

        <Switch>
          <Match when={autoRoles.loading}>
            <div class="flex justify-center h-36">
              <div class="loading loading-spinner loading-lg"></div>
            </div>
          </Match>
          <Match when={autoRoles.error}>
            <tr>
              <td colspan="4">Failed to load the links</td>
            </tr>
          </Match>
        </Switch>

        <div class="flex gap-4 p-4">
          <button
            class="btn btn-primary"
            onclick="select_role_modal.showModal()"
            disabled={!isPremium() && (!autoRoles() || autoRoles().length >= 3)}
            onClick={() => guildRolesRefetch()}
          >
            Add Role
          </button>
        </div>
      </AdminPanel>
    </>
  );
};

export default GuildAutoRole;
