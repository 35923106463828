import { Component, For, Show } from "solid-js";
import AdminModal from "./AdminModal";
import { guildChannels } from "../pages/dashboard/guilds/GuildInformations";

interface AdminChannelSelectorProps {
  title?: string;
  id: string;
  onlyTextChannel?: boolean;
  callback?: any;
}

const AdminChannelSelector: Component<AdminChannelSelectorProps> = (props) => {
  props.onlyTextChannel = props.onlyTextChannel || true;

  function getUniqueCategories(channels: any) {
    return channels
      .sort((a, b) => a.position - b.position)
      .filter((channel) => channel.type === 4)
      .map((channel) => ({ id: channel.id, name: channel.name }));
  }

  function getChannelsByCategory(categoryID: string) {
    return guildChannels()
      .filter((channel) => channel.parentID === categoryID)
      .sort((a, b) => a.position - b.position);
  }

  function getNoCategoryChannels(channels: any) {
    return channels
      .filter((channel) => channel.type !== 4 && !channel.parentID)
      .sort((a, b) => a.position - b.position);
  }

  {
    /* only keep type 4 if onlyTextChannel is true */
  }

  if (props.onlyTextChannel) {
    guildChannels((prevChannels) => prevChannels.filter((channel) => channel.type !== 4));
  }

  return (
    <>
      <AdminModal title={props.title || "Select a Channel"} id={props.id}>
        <Show when={!guildChannels.loading} fallback={<div>Loading...</div>}>
          <div class="form-control">
            <select
              class="select select-bordered"
              onchange="select_channel_modal.close()"
              onChange={async (e) => {
                props.callback && props.callback(e.currentTarget.value);
              }}
            >
              <option value="0">Select a Channel</option>
              <Show when={getNoCategoryChannels(guildChannels()).length > 0}>
                <optgroup label="No Category">
                  <For each={getNoCategoryChannels(guildChannels())}>
                    {(channel) => <option value={channel.id}>{channel.name}</option>}
                  </For>
                </optgroup>
              </Show>
              <For each={getUniqueCategories(guildChannels())}>
                {(category) => (
                  <optgroup label={category.name}>
                    <For each={getChannelsByCategory(category.id)}>
                      {(channel) => <option value={channel.id}>{channel.name}</option>}
                    </For>
                  </optgroup>
                )}
              </For>
            </select>
          </div>
        </Show>
      </AdminModal>
    </>
  );
};

export default AdminChannelSelector;
